<template>
  <div :class="classObject">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TheSuspense',

  props: {
    rounded: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    animated: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classObject() {
      return {
        'the-suspense': true,
        'the-suspense-is-rounded': this.rounded,
        'the-suspense-is-centered': this.centered,
        'the-suspense-is-animated': this.animated,
      }
    },
  },
}
</script>

<style lang="scss">
@import './$suspense.scss';
</style>
